<template>
  <v-container grid-list-md fluid>
    <v-subheader>{{ $t("shop.cart") }}</v-subheader>
    <v-layout row wrap>
      <v-flex xs12 :key="index" v-for="(item, index) in cartItemList">
        <v-card>
          <v-card-text>
            <v-img
              contain
              max-width="500px"
              max-height="180px"
              :src="
                $config.managerMedia + 'picturepool/' + item.shop_item_image
              "
              :lazy-src="
                $config.managerMedia + 'picturepool/' + item.shop_item_image
              "
            ></v-img>
          </v-card-text>
          <v-card-text>
            <v-select
              outlined
              :label="$t('modal.quantitySelection.title')"
              filled
              hide-details
              item-text="quantity"
              :value="item.quantity"
              @input="
                (val) => {
                  modifyCartItemQuantity({
                    cartId: item.cartId,
                    quantity: parseInt(val),
                  });
                }
              "
              :items="quantitys"
            ></v-select>
          </v-card-text>
          <v-card-title class="pb-0">{{ item.shop_item_title }}</v-card-title>
          <v-card-title class="pt-0">
            <div class="title">
              {{
                item.shop_item_price
                  | currency($store.state.session.currencyConfig)
              }}
            </div>
          </v-card-title>
          <v-card-text>
            <div
              v-if="
                item.shop_item_description &&
                item.shop_item_description.length > 0
              "
              class="text-caption pb-2"
            >
              {{ item.shop_item_description }}
            </div>
            <div
              v-for="(addItem, index) in item.purchase_flow_selected"
              :key="index"
            >
              <span class="text-caption">
                + {{ addItem.sub_item_title }}
                {{
                  addItem.sub_item_price
                    | currency($store.state.session.currencyConfig)
                }}
              </span>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-btn
              text
              block
              color="primary"
              @click="
                modifyCartItemQuantity({ cartId: item.cartId, quantity: 0 })
              "
              >{{ $t("shop.removeFromCart") }}</v-btn
            >
          </v-card-actions>
          <v-divider></v-divider>
        </v-card>
      </v-flex>

      <v-flex xs12>
        <div class="subtitle py-3">{{ $t("shop.shopCartDeliveryText") }}</div>
        <v-card flat>
          <v-card-text>
            <template v-if="info.optional_guest_delivery_time">
              <v-dialog
                ref="dialog"
                v-model="dateModal"
                :return-value.sync="deliveryDate"
                persistent
                lazy
                full-width
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="deliveryDate"
                    :label="$t('shop.date')"
                    prepend-icon="mdi-calendar"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="deliveryDate"
                  scrollable
                  :first-day-of-week="1"
                  :min="new Date().toISOString().substr(0, 10)"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="dateModal = false">{{
                    $t("shop.purchaseFlowBack")
                  }}</v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(deliveryDate)"
                    >{{ $t("modal.ok") }}</v-btn
                  >
                </v-date-picker>
              </v-dialog>
              <v-dialog
                ref="timeDialog"
                v-model="timeModal"
                :return-value.sync="deliveryTime"
                persistent
                lazy
                full-width
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="deliveryTime"
                    :label="$t('shop.time')"
                    prepend-icon="mdi-alarm"
                    readonly
                  ></v-text-field>
                </template>
                <v-time-picker
                  format="24hr"
                  v-model="deliveryTime"
                  color="primary"
                  :min="
                    $helpers.formatDate(
                      new Date(new Date().getTime() + shopDeliveryTime * 60000),
                      '%H:%M'
                    )
                  "
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="timeModal = false">{{
                    $t("shop.purchaseFlowBack")
                  }}</v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.timeDialog.save(deliveryTime)"
                    >{{ $t("modal.ok") }}</v-btn
                  >
                </v-time-picker>
              </v-dialog>
            </template>
            <v-text-field
              ref="location"
              :label="$t('shop.location')"
              prepend-icon="mdi-map-marker-outline"
              v-model="location"
              @blur="locationBlurHandler"
              :clearable="location !== userRoomNumber"
            >
              <template v-slot:append-outer>
                <v-btn icon @click="openQrCodeReader">
                  <v-icon>mdi-qrcode-scan</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex xs12 v-if="$config.shop_additional_message_enabled">
        <v-textarea
          :label="$t('shop.additionalMessage')"
          v-model="additionalMessage"
        >
        </v-textarea>
      </v-flex>

      <v-flex xs12>
        <v-card>
          <v-card-title class="text-subtitle-2 pb-2">{{
            $t("shop.total")
          }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="title">{{
            parseAndSumList(cartItemList)
              | currency($store.state.session.currencyConfig)
          }}</v-card-text>

          <v-card-text v-if="statusTransmit === 'failed'">
            <v-alert type="error">
              {{ $t("shop.placeOrderFailed") }}
            </v-alert>
          </v-card-text>

          <v-card-text
            v-if="
              !$store.state.session.authResult.checkin ||
              !$store.state.session.authResult.checkin[0] ||
              !$store.state.session.authResult.checkin[0].name
            "
          >
            <v-alert type="info">
              {{ $t("shop.noLogin") }}
            </v-alert>
          </v-card-text>

          <v-card-actions>
            <v-btn
              :disabled="
                !$store.state.session.authResult.checkin ||
                !$store.state.session.authResult.checkin[0] ||
                !$store.state.session.authResult.checkin[0].name
              "
              @click="placeOrder()"
              :loading="statusTransmit === 'sending'"
              block
              color="primary"
            >
              {{ $t("shop.placeOrder") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog style="max-width: 400px" v-model="successSendOrderMessage">
      <v-card>
        <v-card-title class="title">{{
          $t("shop.successSendOrder")
        }}</v-card-title>
        <v-card-text v-if="info.optional_guest_delivery_time">{{
          $t("shop.guestDeliveryTimeSelected", {
            date: deliveryDate,
            time: deliveryTime,
          })
        }}</v-card-text>
        <v-card-text v-else>{{
          $t("shop.deliveredAsSoonAsPossible")
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="successSendOrderMessage = false"
            text
            color="primary"
            >{{ $t("modal.ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import each from "lodash/each";
import { DateTime } from "luxon";

export default {
  data() {
    return {
      group: undefined,
      quantity: 1,
      dateModal: false,
      timeModal: false,
      deliveryDate: new Date().toISOString().substr(0, 10),
      deliveryTime: new Date().toISOString().substr(11, 5),
      successSendOrderMessage: false,
      additionalMessage: "",
      location: "",
    };
  },
  mounted() {
    this.location = this.otherLocation
      ? this.otherLocation
      : this.userRoomNumber;
  },
  methods: {
    ...mapActions("shop", [
      "getInfo",
      "getContent",
      "setActiveGroup",
      "transmitOrder",
      "setActiveItemListData",
      "setPurchaseFlowCache",
      "modifyPurchaseFlowSubitemSelected",
      "addToCartList",
      "modifyCartItemQuantity",
      "addPurchaseFlowItemToCart",
      "resetCartList",
      "reduceCartListDuplicates",
    ]),
    ...mapActions(["openQrCodeReader"]),
    initData() {
      this.$nextTick(() => {
        this.deliveryTime = this.$helpers.formatDate(
          new Date(new Date().getTime() + (this.shopDeliveryTime || 0) * 60000),
          "%H:%M"
        );
      });
      this.getInfo(this.$route.params.id || this.cartItemList[0].shopId);
      this.getContent(this.$route.params.id);
    },
    setOtherLocation(val) {
      if (val) {
        this.$router.push({
          path: this.$router.currentRoute.path,
          query: { ...this.$router.currentRoute.query, location: val },
        });
      } else {
        this.$router.push({
          path: this.$router.currentRoute.path,
          query: { ...this.$router.currentRoute.query, location: undefined },
        });
      }
    },
    parseAndSum: function (a, b) {
      const aParsed = parseFloat(a);
      const bParsed = parseFloat(b);
      if (isFinite(aParsed) && isFinite(bParsed)) {
        return aParsed + bParsed;
      }
      return "";
    },
    parseAndSumList: function (list) {
      const _this = this;
      var sum = 0;
      each(list, function (element) {
        const parsedPrice = parseFloat(element.shop_item_price);
        const parsedQuantity = parseFloat(element.quantity);
        var subItemSum = 0;
        each(element.purchase_flow_selected, function (subItem) {
          subItemSum +=
            _this.parseAndSum(
              subItem.sub_item_price,
              subItem.sub_item_price_diff
            ) * parsedQuantity;
        });
        if (isFinite(parsedPrice) && isFinite(parsedQuantity)) {
          sum += parsedPrice * parsedQuantity + subItemSum;
        }
      });
      return sum;
    },
    async placeOrder() {
      if (this.statusTransmit === "sending") {
        return;
      }

      await this.getInfo(this.$route.params.id || this.cartItemList[0].shopId);
      // check again if shop is still open
      // else info mutation will redirect to home automatically
      if (this.$store.state.shop.info.isOpen) {
        this.transmitOrder({
          retailId: this.cartItemList[0].shopId,
          additionalMessage: this.additionalMessage,
          deliveryTime: new DateTime.fromFormat(
            this.deliveryDate + " " + this.deliveryTime + ":00",
            "yyyy-MM-dd HH:mm:ss"
          ).toJSDate(),
        });
      }
    },
    locationBlurHandler() {
      if (!this.location) {
        this.setOtherLocation("");
        this.location = this.userRoomNumber;
      } else {
        this.setOtherLocation(this.location);
      }
    },
  },
  computed: {
    ...mapState("shop", {
      statusTransmit: (state) => state.statusTransmit,
      statusContent: (state) => state.statusContent,
      content: (state) => state.content,
      activeGroup: (state) => state.activeGroup,
      parentGroup: (state) => state.parentGroup,
      rootTemplateData: (state) => state.rootTemplateData,
      info: (state) => state.info,
      cartItemList: (state) => state.cartItemList,
    }),
    ...mapState("session", {
      loginDataRoom: (state) => state.loginData.room,
    }),
    otherLocation() {
      return this.$route.query.location;
    },
    userRoomNumber() {
      if (this.loginDataRoom) {
        return this.$t("login.room") + " " + this.loginDataRoom;
      }
      return "";
    },
    quantitys() {
      var q = [];
      for (var i = 1; i < 101; i++) {
        q.push({ quantity: i });
      }
      return q;
    },
    shopDeliveryTime() {
      return parseInt(this.info.delivery_time);
    },
  },
  created() {
    this.initData();
  },
  watch: {
    "$route.params.id"(val) {
      this.initData();
    },
    cartItemList(val) {
      if (val.length < 1) {
        this.$router.push({ name: "home" });
      }
    },
    statusTransmit(val) {
      if (val === "success") {
        this.successSendOrderMessage = true;
      }
    },
    successSendOrderMessage(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.resetCartList();
      }
    },
    shopDeliveryTime(val) {
      if (val > 0) {
        this.deliveryTime = this.$helpers.formatDate(
          new Date(new Date().getTime() + val * 60000),
          "%H:%M"
        );
      }
    },
    otherLocation(val) {
      this.location = val ? val : this.userRoomNumber;
    },
    location() {
      this.$refs.location.blur();
    },
  },
};
</script>
