var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-md":"","fluid":""}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$t("shop.cart")))]),_c('v-layout',{attrs:{"row":"","wrap":""}},[_vm._l((_vm.cartItemList),function(item,index){return _c('v-flex',{key:index,attrs:{"xs12":""}},[_c('v-card',[_c('v-card-text',[_c('v-img',{attrs:{"contain":"","max-width":"500px","max-height":"180px","src":_vm.$config.managerMedia + 'picturepool/' + item.shop_item_image,"lazy-src":_vm.$config.managerMedia + 'picturepool/' + item.shop_item_image}})],1),_c('v-card-text',[_c('v-select',{attrs:{"outlined":"","label":_vm.$t('modal.quantitySelection.title'),"filled":"","hide-details":"","item-text":"quantity","value":item.quantity,"items":_vm.quantitys},on:{"input":function (val) {
                _vm.modifyCartItemQuantity({
                  cartId: item.cartId,
                  quantity: parseInt(val),
                });
              }}})],1),_c('v-card-title',{staticClass:"pb-0"},[_vm._v(_vm._s(item.shop_item_title))]),_c('v-card-title',{staticClass:"pt-0"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.shop_item_price,_vm.$store.state.session.currencyConfig))+" ")])]),_c('v-card-text',[(
              item.shop_item_description &&
              item.shop_item_description.length > 0
            )?_c('div',{staticClass:"text-caption pb-2"},[_vm._v(" "+_vm._s(item.shop_item_description)+" ")]):_vm._e(),_vm._l((item.purchase_flow_selected),function(addItem,index){return _c('div',{key:index},[_c('span',{staticClass:"text-caption"},[_vm._v(" + "+_vm._s(addItem.sub_item_title)+" "+_vm._s(_vm._f("currency")(addItem.sub_item_price,_vm.$store.state.session.currencyConfig))+" ")])])})],2),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","block":"","color":"primary"},on:{"click":function($event){return _vm.modifyCartItemQuantity({ cartId: item.cartId, quantity: 0 })}}},[_vm._v(_vm._s(_vm.$t("shop.removeFromCart")))])],1),_c('v-divider')],1)],1)}),_c('v-flex',{attrs:{"xs12":""}},[_c('div',{staticClass:"subtitle py-3"},[_vm._v(_vm._s(_vm.$t("shop.shopCartDeliveryText")))]),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[(_vm.info.optional_guest_delivery_time)?[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.deliveryDate,"persistent":"","lazy":"","full-width":"","width":"290px"},on:{"update:returnValue":function($event){_vm.deliveryDate=$event},"update:return-value":function($event){_vm.deliveryDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$t('shop.date'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.deliveryDate),callback:function ($$v) {_vm.deliveryDate=$$v},expression:"deliveryDate"}},on))]}}],null,false,556198553),model:{value:(_vm.dateModal),callback:function ($$v) {_vm.dateModal=$$v},expression:"dateModal"}},[_c('v-date-picker',{attrs:{"scrollable":"","first-day-of-week":1,"min":new Date().toISOString().substr(0, 10)},model:{value:(_vm.deliveryDate),callback:function ($$v) {_vm.deliveryDate=$$v},expression:"deliveryDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateModal = false}}},[_vm._v(_vm._s(_vm.$t("shop.purchaseFlowBack")))]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.deliveryDate)}}},[_vm._v(_vm._s(_vm.$t("modal.ok")))])],1)],1),_c('v-dialog',{ref:"timeDialog",attrs:{"return-value":_vm.deliveryTime,"persistent":"","lazy":"","full-width":"","width":"290px"},on:{"update:returnValue":function($event){_vm.deliveryTime=$event},"update:return-value":function($event){_vm.deliveryTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$t('shop.time'),"prepend-icon":"mdi-alarm","readonly":""},model:{value:(_vm.deliveryTime),callback:function ($$v) {_vm.deliveryTime=$$v},expression:"deliveryTime"}},on))]}}],null,false,3222743736),model:{value:(_vm.timeModal),callback:function ($$v) {_vm.timeModal=$$v},expression:"timeModal"}},[_c('v-time-picker',{attrs:{"format":"24hr","color":"primary","min":_vm.$helpers.formatDate(
                    new Date(new Date().getTime() + _vm.shopDeliveryTime * 60000),
                    '%H:%M'
                  )},model:{value:(_vm.deliveryTime),callback:function ($$v) {_vm.deliveryTime=$$v},expression:"deliveryTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.timeModal = false}}},[_vm._v(_vm._s(_vm.$t("shop.purchaseFlowBack")))]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.timeDialog.save(_vm.deliveryTime)}}},[_vm._v(_vm._s(_vm.$t("modal.ok")))])],1)],1)]:_vm._e(),_c('v-text-field',{ref:"location",attrs:{"label":_vm.$t('shop.location'),"prepend-icon":"mdi-map-marker-outline","clearable":_vm.location !== _vm.userRoomNumber},on:{"blur":_vm.locationBlurHandler},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.openQrCodeReader}},[_c('v-icon',[_vm._v("mdi-qrcode-scan")])],1)]},proxy:true}]),model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})],2)],1)],1),(_vm.$config.shop_additional_message_enabled)?_c('v-flex',{attrs:{"xs12":""}},[_c('v-textarea',{attrs:{"label":_vm.$t('shop.additionalMessage')},model:{value:(_vm.additionalMessage),callback:function ($$v) {_vm.additionalMessage=$$v},expression:"additionalMessage"}})],1):_vm._e(),_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',[_c('v-card-title',{staticClass:"text-subtitle-2 pb-2"},[_vm._v(_vm._s(_vm.$t("shop.total")))]),_c('v-divider'),_c('v-card-text',{staticClass:"title"},[_vm._v(_vm._s(_vm._f("currency")(_vm.parseAndSumList(_vm.cartItemList),_vm.$store.state.session.currencyConfig)))]),(_vm.statusTransmit === 'failed')?_c('v-card-text',[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.$t("shop.placeOrderFailed"))+" ")])],1):_vm._e(),(
            !_vm.$store.state.session.authResult.checkin ||
            !_vm.$store.state.session.authResult.checkin[0] ||
            !_vm.$store.state.session.authResult.checkin[0].name
          )?_c('v-card-text',[_c('v-alert',{attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.$t("shop.noLogin"))+" ")])],1):_vm._e(),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":!_vm.$store.state.session.authResult.checkin ||
              !_vm.$store.state.session.authResult.checkin[0] ||
              !_vm.$store.state.session.authResult.checkin[0].name,"loading":_vm.statusTransmit === 'sending',"block":"","color":"primary"},on:{"click":function($event){return _vm.placeOrder()}}},[_vm._v(" "+_vm._s(_vm.$t("shop.placeOrder"))+" ")])],1)],1)],1)],2),_c('v-dialog',{staticStyle:{"max-width":"400px"},model:{value:(_vm.successSendOrderMessage),callback:function ($$v) {_vm.successSendOrderMessage=$$v},expression:"successSendOrderMessage"}},[_c('v-card',[_c('v-card-title',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("shop.successSendOrder")))]),(_vm.info.optional_guest_delivery_time)?_c('v-card-text',[_vm._v(_vm._s(_vm.$t("shop.guestDeliveryTimeSelected", { date: _vm.deliveryDate, time: _vm.deliveryTime, })))]):_c('v-card-text',[_vm._v(_vm._s(_vm.$t("shop.deliveredAsSoonAsPossible")))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.successSendOrderMessage = false}}},[_vm._v(_vm._s(_vm.$t("modal.ok")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }